import { ComponentPropsWithoutRef } from 'react';
import cn from 'classnames';
import { PropsWithTestId } from '../../../../../../../types';
import s from './TestimonialHeadline.module.scss';

export interface TestimonialHeadlineProps
  extends Pick<ComponentPropsWithoutRef<'div'>, 'className'> {
  text: string;
}

export default function TestimonialHeadline(
  props: PropsWithTestId<TestimonialHeadlineProps>,
) {
  const { text, className } = props;

  return <div className={cn(s.headline, className)}>{text}</div>;
}
