import React from 'react';
import classnames from 'classnames';
import {
  HubModule,
  HubModuleCTA,
  PropsWithTestId,
  UploadedImage,
} from '../../../../../../types';
import { HubModuleWrapper } from '../../HubModuleWrapper';
import { HubModuleTypes } from '../../../../../../constants';
import { CallToAction } from '../../../CallToActionGroup';
import Carousel from '../../../../../shared/Carousel/Carousel';
import ImageTag from '../../../../../shared/ImageTag/ImageTag';
import { TestimonialItem } from './TestimonialItem';
import { TestimonialName } from './TestimonialName';
import { TestimonialCompany } from './TestimonialCompany';
import { TestimonialJob } from './TestimonialJob';
import { TestimonialQuote } from './TestimonialQuote';
import TestimonialHeadline from './TestimonialHeadline';
import styles from './TestimonialsModule.module.scss';

export interface TestimonialsModuleType extends HubModule {
  '@type': HubModuleTypes.Testimonials;
  link: HubModuleCTA;
  rotateType: 'STATIC' | 'SMOOTH' | 'ONE_AT_TIME';
  displayOrder: 'AS_LISTED' | 'RANDOM';
  testimonials: Array<{
    id: string;
    name?: string;
    company?: string;
    jobTitle?: string;
    headline?: string;
    quote?: string;
    shown?: boolean;
    testimonialLogo: UploadedImage;
  }>;
}

export interface TestimonialsModuleProps {
  module: TestimonialsModuleType;
}

const carouselSettings = {
  dots: false,
  arrows: false,
  autoplay: false,
  infinite: true,
};

const customCarouselSettings = {
  STATIC: {
    dots: true,
    autoplay: false,
  },
  SMOOTH: {
    dots: false,
    autoplay: true,
    speed: 8000,
    autoplaySpeed: 0,
    cssEase: 'linear',
  },
  ONE_AT_TIME: {
    dots: true,
    autoplay: true,
    autoplaySpeed: 5000,
  },
};

export default function TestimonialsModule({
  module: {
    '@type': type,
    testimonials,
    elementId,
    link,
    visualStyle,
    rotateType,
  },
  testId,
}: PropsWithTestId<TestimonialsModuleProps>) {
  const itemRowClassName = classnames('row', styles.itemRowContainer);

  return (
    <HubModuleWrapper
      type={type}
      theme={visualStyle}
      elementId={elementId}
      className={classnames('hub-testimonials', styles.wrapper)}
      testId={testId}
    >
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            {testimonials.length > 0 && (
              <Carousel
                embedSettings={{
                  ...carouselSettings,
                  ...customCarouselSettings[rotateType],
                }}
              >
                {testimonials.map(
                  ({
                    id,
                    name,
                    jobTitle,
                    company,
                    headline,
                    quote,
                    testimonialLogo,
                  }) => (
                    <TestimonialItem key={id}>
                      <div className={styles.itemContainer}>
                        <div className={itemRowClassName}>
                          <div className="col-xs-12 col-sm-3">
                            {(name ||
                              jobTitle ||
                              company ||
                              testimonialLogo) && (
                              <div className={styles.itemLeft}>
                                {name && (
                                  <TestimonialName>{name}</TestimonialName>
                                )}
                                {jobTitle && (
                                  <TestimonialJob className={styles.jobOffset}>
                                    {jobTitle}
                                  </TestimonialJob>
                                )}
                                {company && (
                                  <TestimonialCompany>
                                    {company}
                                  </TestimonialCompany>
                                )}
                                {testimonialLogo && (
                                  <ImageTag
                                    className={styles.holder}
                                    src={testimonialLogo.path}
                                    alt=""
                                  />
                                )}
                              </div>
                            )}
                          </div>
                          <div className="col-xs-12 col-sm-9">
                            {headline && (
                              <TestimonialHeadline
                                text={headline}
                                className={styles.itemRight}
                              />
                            )}
                            {quote && (
                              <TestimonialQuote
                                className={styles.itemRight}
                                text={quote}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </TestimonialItem>
                  ),
                )}
              </Carousel>
            )}
            {link?.type && (
              <CallToAction
                link={link}
                variant="contained"
                align="center"
                className={styles.ctaButton}
                data-testid="testimonial-module-link"
              />
            )}
          </div>
        </div>
      </div>
    </HubModuleWrapper>
  );
}

TestimonialsModule.defaultProps = {
  testId: 'hub-testimonials-module',
};
