import { HTMLAttributes } from 'react';
import cn from 'classnames';
import style from './TestimonialQuote.module.scss';
import { PropsWithTestId } from '../../../../../../../types';
import { HTMLtoJSX } from '../../../../../../../helpers';

export interface TestimonialQuoteProps extends HTMLAttributes<HTMLElement> {
  text: string;
}

export const TestimonialQuote = (
  props: PropsWithTestId<TestimonialQuoteProps>,
) => {
  const { className, testId, text, ...restProps } = props;
  return (
    <div
      {...restProps}
      data-testid={testId}
      className={cn(style.paragraph, className)}
    >
      {HTMLtoJSX(text)}
    </div>
  );
};

TestimonialQuote.defaultProps = {
  testId: 'testimonial-quote',
};
