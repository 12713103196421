import { HTMLAttributes, PropsWithChildren } from 'react';
import cn from 'classnames';
import style from './TestimonialName.module.scss';
import { PropsWithTestId } from '../../../../../../../types';

export type TestimonialNameProps = PropsWithTestId<
  PropsWithChildren<HTMLAttributes<HTMLElement>>
>;

export const TestimonialName = (props: TestimonialNameProps) => {
  const { className, children, testId, ...restProps } = props;
  return (
    <p
      {...restProps}
      data-testid={testId}
      className={cn(style.paragraph, className)}
    >
      {children}
    </p>
  );
};

TestimonialName.defaultProps = {
  testId: 'testimonial-name',
};
