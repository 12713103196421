import { HTMLAttributes, PropsWithChildren } from 'react';
import cn from 'classnames';
import style from './TestimonialCompany.module.scss';
import { PropsWithTestId } from '../../../../../../../types';

export type TestimonialCompanyProps = PropsWithTestId<
  PropsWithChildren<HTMLAttributes<HTMLElement>>
>;

export const TestimonialCompany = (props: TestimonialCompanyProps) => {
  const { className, children, testId, ...restProps } = props;
  return (
    <p
      {...restProps}
      data-testid={testId}
      className={cn(style.paragraph, className)}
    >
      {children}
    </p>
  );
};

TestimonialCompany.defaultProps = {
  testId: 'testimonial-company',
};
