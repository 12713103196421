import React, { HTMLAttributes, PropsWithChildren } from 'react';
import cn from 'classnames';
import { PropsWithTestId } from '../../../../../../../types';
import styles from './TestimonialItem.module.scss';

export function TestimonialItem({
  className,
  testId,
  children,
  ...restProps
}: PropsWithTestId<PropsWithChildren<HTMLAttributes<HTMLElement>>>) {
  const itemClassName = cn(styles.wrapper, className);

  return (
    <div {...restProps} data-testid={testId} className={itemClassName}>
      {children}
    </div>
  );
}

TestimonialItem.defaultProps = {
  testId: 'testimonial-heading',
};
